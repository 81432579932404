$( document ).ready(function() {
  
  setTimeout(function() { 
    
    $('nav .navbar-brand svg').addClass('in-view');
    if ($(window).width() > 1024) {
      var HeadlineHeight = [];
      $('div.bvs_block--bvs_zertifizierung.bvs_block--bvs_zertifizierunglogo .row .item').each(function( index ) {
        var heights = $(this).find('h4').outerHeight();
        HeadlineHeight.push(heights);
      });
      var biggestHeight = Math.max.apply(Math,HeadlineHeight);
      $('div.bvs_block--bvs_zertifizierung.bvs_block--bvs_zertifizierunglogo .row .item h4').css('min-height' , biggestHeight);
    }
    var NachhaltigkeitBoxHeight = [];
    $('#nachhaltigkeit.bvs_block--bvs_zertifizierung .row .item').each(function( index ) {
      var heights = $(this).find('h4').outerHeight();
      NachhaltigkeitBoxHeight.push(heights);
    });
    var biggestBoxHeight = Math.max.apply(Math,NachhaltigkeitBoxHeight);
    $('#nachhaltigkeit.bvs_block--bvs_zertifizierung .row .item').css('min-height' , biggestBoxHeight);
    
     
    $('a.btn.readmorebtn').on('click', function(){
      $(this).toggleClass('readmorebtnactive');
      if ($(this).hasClass('readmorebtnactive')) {
          $(this).text('weniger lesen');
      }else{
        $(this).text('weiterlesen');
      }
    });  
    
    
    
    $('#zertifizierungen.bvs_block.bvs_block--bvs_zertifizierung .row .item').first().find('.item-wrapper').addClass('active'); 
    
    $('#zertifizierungen.bvs_block.bvs_block--bvs_zertifizierung .row .item').on("mouseover", function(e){
      $('#zertifizierungen.bvs_block.bvs_block--bvs_zertifizierung .row .item .item-wrapper.active').removeClass("active");
    });

  }, 350);
  
  function bvsBSinit(){
    
    $('.bvs-betterslide').each(function( index ) {
    var bvsBS          = $(this);
    var bvsBSwrap      = bvsBS.find('.bvs-betterslide-wrap');
    var bvsBSitems     = bvsBS.find('.bvsbs-item');
    var bvsBSnum       = bvsBS.data('num-lg');
    var bvsBScurr      = 0;
    var bvsBStime      = 4000;
    var bvsnav_prev    = bvsBS.find('.carousel-control-prev');
    var bvsBSinterval  = undefined;

    if($(window).width() < 1201){
      bvsBSnum = bvsBS.data('num-sm');
    }
    if($(window).width() < 641){
      bvsBSnum = bvsBS.data('num-xs');
    }

    var bvsBSitemwidth = (bvsBS.innerWidth() / bvsBSnum);
    bvsBSitems.css({'width':bvsBSitemwidth});
    bvsBSwrap.css({'width':(bvsBS.innerWidth() / bvsBSnum * bvsBSitems.length)});
    bvsBS.addClass('is-loaded');

    function bvsBStransition(){
      if(bvsBScurr < bvsBSitems.length - bvsBSnum){
        bvsBScurr = bvsBScurr + 1;
        $(bvsBSwrap).css({'transform':'translateX(-' + (bvsBScurr * (bvsBS.innerWidth() / bvsBSnum)) + 'px)'});
      } else {
        $(bvsBSwrap).css({'transform':'translateX(0)'});
        bvsBScurr = 0;
      }
    }

    bvsnav_prev.on('click', function(){
      clearInterval(bvsBSinterval);
      if(bvsBScurr < bvsBSitems.length - bvsBSnum){
        bvsBScurr = bvsBScurr - 1;
        $(bvsBSwrap).css({'transform':'translateX(-' + (bvsBScurr * (bvsBS.innerWidth() / bvsBSnum)) + 'px)'});
      } else {
        $(bvsBSwrap).css({'transform':'translateX(0)'});
        bvsBScurr = 0;
      }
      bvsBSinterval = setInterval(bvsBStransition,bvsBStime);
    });

    bvsBSinterval = setInterval(bvsBStransition,bvsBStime);
    });
    
  }

    //bvsBSinit();


  


  if ($('body').hasClass('page-template-page-home') || $('body').hasClass('page-id-126') || $('body').hasClass('page-id-207')){
    
    $('.sticky-top.sticky-item-container .sticky-item').addClass('active'); 
    var sticky = $('.sticky-top.sticky-item-container .sticky-item');
    
    $(document).scroll(function() {    
      var scroll = $(this).scrollTop();
      if (scroll >= 250) {
        $(sticky).removeClass('active');
      }else{
        $(sticky).addClass('active');
      }
    });
    
    $(sticky).click(function() {
      $(this).toggleClass("active");
    });
    
  }else{
    var sticky = $('.sticky-top.sticky-item-container .sticky-item');
    $(sticky).click(function() {
      $(this).toggleClass("active");
    });
  }
  
  if ($(window).width() < 1201) {
    var legallinks = $('footer #subfooter .col-12.justify-content-end').html();
    legallinks = '<div class="mobile-nav-legallinks">'+legallinks+'</div>';
    $('nav #navbarSupportedContent').append(legallinks);
  }
  
  $('.mobile-toggles .navbar-toggler').on('click', function(){
    $('nav.navbar').toggleClass('active');
    $(this).toggleClass('active'); 
  });
  
  if ($(window).width() < 767) {
    $('.bvs_block.bvs_block--bvs_offsetcontent').each(function( index ) {
      var headlines   = $( this ).find('.container > .row > .col-12 > .row > .col-12 > h2').text();
      var textwrapper = $( this ).find('.container > .row > .col-12.col-lg-9');
      var imgwrapper  = $( this ).find('.container > .row > .col-12.img-wrapper');
    });
  }
  
});